<template>
  <div class="single-sorting-container">

    <div class="header">
      <div style="width: 205px">
        <logotop></logotop>
      </div>
      <div class="sortingformbox">
        <div class="box-left">
          <a-button class="back-btn bgBlue"  type="primary" style="padding:0px 28px;" @click="go_back">
            <a-icon type="arrow-left"/>
            返回
          </a-button>
          <a-button class="back-btn bgBlue"  type="primary" style="padding:0px 28px;" @click="reloadAction">
            <a-icon type="reload"/>
            刷新
          </a-button>
        </div>
        <div class="btn-form-line"></div>
        <div class="form-container">
          <a-config-provider :locale="locale">
            <a-form-model  class="sortingForm" layout="inline" style="width:100%" >
              <a-form-model-item class="itemFormItem" style="flex: 1">
<!--                <a-select-->
<!--                        :dropdown-match-select-width="false"-->
<!--                        @change="beforeLoadData('r')"-->
<!--                        allow-clear-->
<!--                        show-search-->
<!--                        v-model="dataObj.user_name"-->

<!--                        placeholder="客户搜索"-->
<!--                        :default-active-first-option="false"-->
<!--                        :show-arrow="true"-->
<!--                        :filter-option="false"-->
<!--                        :not-found-content="null"-->
<!--                        @search="handleUserSearch"-->
<!--                >-->
<!--                  <a-select-option  key="loading" v-if="userLoading">-->
<!--                    <a-spin/>-->
<!--                  </a-select-option>-->
<!--                  <a-select-option v-for="d in userList" :key="d.name">-->
<!--                    {{ d.name }}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
                <a-input v-model="dataObj.client_name" placeholder="客户搜索" style="text-align: center"  @change="searchChange">
                  <a-icon slot="suffix" type="search" :style="{fontSize: '30px', color: '#d9d9d9'}" />
                </a-input>
              </a-form-model-item>

              <a-form-model-item>
                <a-button class="back-btn filter-btn" size="large" type="primary" style="padding:0px 28px;" @click="showFilterAction">
                  筛选
                </a-button>
              </a-form-model-item>


            </a-form-model>
          </a-config-provider>

        </div>
      </div>
    </div>


    <a-spin :spinning="loading">

      <div class="content-view">
      <a-empty class="nodataempty" v-if="datalist.length==0">
        <span slot="description">暂无数据</span>
      </a-empty>
      <div class="sorting-detail-view" @scroll="listenScroll" v-if="datalist.length!==0">
        <a-row :gutter="10">
          <a-col class="gutter-row" :span="6" v-for="(item,index) in datalist" :key="index"
                 @click="sortingAction(item)">
            <div class="sorting-detail-item">
              <div class="item-status" :class="item.sorting_status == '2' ? 'statusBg-2' : 'statusBg-1'">
                {{ item.sorting_status == '2' ? '已完成' : '未完成' }}
              </div>
              <div class="entity-name-container" :ref="'entity-name-container' + index">
                <div class="entity-name" :ref="'entity-name' + index" :style="{'transform': item.nameScale}">
                  {{item.name}}
                </div>
              </div>


              <div class="num-info" :class="item.sorting_status == '2' ? 'statusBg-2' : 'statusBg-1'">
                分拣进度：{{item.sorted}}/{{item.order_total}}
              </div>

            </div>
          </a-col>

        </a-row>
        <load-more :status="loadingType" style="margin-top:10px"></load-more>
      </div>
      </div>
    </a-spin>



<!--    筛选-->
    <a-drawer
            title="筛选"
            placement="top"
            :closable="false"
            :visible="isShowFilter"
            @close="onCloseFilter"
            :maskClosable="false"
            height="auto"
    ><a-config-provider :locale="locale">
      <a-form-model class="filter-input">
        <a-form-model-item>
          <div class="input-label">发货日期</div>
          <div style="display: flex;align-items: center">
            <div class="date-item" @click="dateChange('-1')">昨天</div>
            <div class="date-item" @click="dateChange('1')">今天</div>
            <div class="date-item" @click="dateChange('+1')">明天</div>
            <a-date-picker :allow-clear="false" size="large"  value-format="YYYY-MM-DD" format="YYYY-MM-DD" v-model="filterValues.send_time" :locale="locale"
                           placeholder="发货日期"/>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <div class="input-label">分拣状态</div>
          <div class="radio-group">
            <div class="radio-item" @click="filterSortingStatusChange('')">
              <div class="radio-dot">
                <div v-if="filterValues.sorting_status === ''"></div>
              </div>
              <div class="radio-text">全部</div>
            </div>
            <div class="radio-item" v-for="item in sortStatusList" @click="filterSortingStatusChange(item.id)">
              <div class="radio-dot">
                <div v-if="item.id === filterValues.sorting_status"></div>
              </div>
              <div class="radio-text">{{item.name}}</div>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <div class="input-label">路线</div>
          <div class="radio-group">
            <div class="radio-item" @click="filterLineChange()">
              <div class="radio-dot check-dot">
                <div  v-if="lineAll"></div>
              </div>
              <div class="radio-text">全部</div>
            </div>
            <div class="radio-item" v-for="item in lines" @click="filterLineChange(item.id)">
              <div class="radio-dot check-dot">
                <div v-if="filterValues.line_id.indexOf(item.id) > -1"></div>
              </div>
              <div class="radio-text">{{item.name}}</div>
            </div>
          </div>
        </a-form-model-item>
      </a-form-model>
    </a-config-provider>
      <div class="filter-btns">
        <a-button class="filter-btn cancel-btn" @click="filterCancel">取消</a-button>
        <a-button class="filter-btn confire-btn" @click="filterConfirm">确认</a-button>
      </div>
    </a-drawer>

  </div>
</template>

<script>
  import logotop from "@/components/logotop"; //顶部logo
  import {PrintAccount, CheckIsP} from '@/assets/js/doPrint'
  import loadMore from "@/components/loadMore"; //顶部查询
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
  import {timeConvert} from '@/assets/js/common'
  import moment from 'moment';
  import {mapMutations} from "vuex";

  export default {
    name: "single_sorting",
    components: {
      logotop,
      loadMore
    },
    data() {
      return {
        sortStatusList: [],
        locale: zhCN,
        LOCK_STATUS: false,
        loading: false,
        otherInfo: null,
        loadingType: 'loading',
        datalist: [],
        moment,
        lines: [],
        dataObj: {
          send_time: null,
          client_name: '',
          line_id: [],
          sorting_status: ''
        },
        page: 1,
        isShowFilter: false,
        filterValues: {
          send_time: null,
          line_id: [],
          sorting_status: ''
        },
        oldUrl: null,
        userLoading: false,
        userList:[],
        searchTimeout: null
      }
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.oldUrl = from.name
      })
    },
    activated() {
      this.$nextTick(() => {
        if (this.oldUrl !== 'customerSortingPro') {
          this.datalist = [];
          this.formReset();
        }
      })
    },
    created() {
      let newDate = new Date();
      this.dataObj.send_time = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
    },
    mounted() {

      this.getclassfiy();
      this.page = 1;
      this.loadData(this.page)
    },
    computed: {
        lineAll() {
          if (this.filterValues.line_id) {
            for (let j = 0; j < this.lines.length; j++) {
              let have = false;
              for (let i = 0; i < this.filterValues.line_id.length; i++) {
                if (this.filterValues.line_id[i] === this.lines[j].id)  {
                  have = true
                }
              }
              if (have === false) {
                return false
              }
            }
            return true;

          } else {
            return false;
          }
        }
    },
    methods: {
      searchChange(e) {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
          this.beforeLoadData('r')
        }, 500)
      },
      filterCancel() {

          this.isShowFilter = false;
      },
      filterConfirm() {
        this.dataObj = Object.assign({}, this.dataObj, this.filterValues)
        this.filterCancel();
        this.beforeLoadData('r')
      },
      filterSortingStatusChange(value) {
        this.filterValues.sorting_status = value
      },
      isChosenAllLine() {
        if (this.filterValues.line_id) {
          for (let j = 0; j < this.lines.length; j++) {
            let have = false;
            for (let i = 0; i < this.filterValues.line_id.length; i++) {
              if (this.filterValues.line_id[i] === this.lines[j].id)  {
                have = true
              }
            }
            if (have === false) {
              return false
            }
          }
          return true;

        } else {
          return false;
        }
      },
      filterLineChange(value) {
        if (!value) {
          if (this.isChosenAllLine()) {
            this.filterValues.line_id = []
          } else {
            this.filterValues.line_id = this.lines.map(v => {
              return v.id;
            })
          }
        } else {
          let index = this.filterValues.line_id.indexOf(value)
          let newLines = [...this.filterValues.line_id]
          if (index > -1) {
            newLines.splice(index, 1)
          } else {
            newLines.push(value)
          }
          this.filterValues.line_id = newLines
        }
      },
      onCloseFilter() {
        this.filterValues = {
          send_time: null,
          line_id: [],
          sorting_status: ''
        }
      },
      showFilterAction() {
        this.filterValues = {
          send_time: this.dataObj.send_time,
          line_id: this.dataObj.line_id ||  [],
          sorting_status: this.dataObj.sorting_status
        }
        this.isShowFilter = true
      },




      getclassfiy() {
        this.rq.get('/merapi/sorting/v2/common/code-list').then(res => {

          this.lines = res.data.lineList
          this.cateList = res.data.cateList
          this.sortStatusList = res.data.sortStatusList
        })
      },




      go_back() {
        this.$router.back()
      },

      listenScroll(e) {
        if (this.LOCK_STATUS) return;
        if (e instanceof Event) {
          var el = e.target;
          var scHeight = el.scrollHeight, scTop = el.scrollTop, clHeight = el.clientHeight;
          //距离底部100px时，开始加载数据
          if (scHeight - scTop > clHeight + 20) return;
        }
        this.LOCK_STATUS = true;
        if (this.loadingType === 'nomore' || this.datalist.length === 0) return;
        this.page++;
        this.loadData(this.page);
      },
      formReset() {
        this.dataObj = {
          send_time: null,
          client_name: '',
          line_id: [],
          sorting_status: ''
        }
        let newDate = new Date();
        this.dataObj.send_time = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
        this.page = 1;
        this.loadData(this.page)
      },
      search() {
        this.page = 1;
        this.loadData(this.page)
      },

      beforeLoadData(type) {
        if (type === 'r') {
          this.page = 1;
        } else {
          this.page += 1;
        }
        this.loadData(this.page)
      },
      loadData(page) {
        this.loading = true;
        let p = {}
        for (let key in this.dataObj) {
          if (this.dataObj[key] !== null && this.dataObj[key] !== undefined && this.dataObj[key] !== '') {
            if (key === 'line_id' && this.dataObj[key].length !== 0) {
              p['line_id'] = this.dataObj[key].join(',')
            } else {
              p[key] = this.dataObj[key]
            }
          }
        }
        p.page = page;
        p.page_size = 16
        this.rq.get('/merapi/sorting/v2/client/client-list', {params: p}).then(res => {
          this.loading = false;
          this.LOCK_STATUS = false;
          this.loadingType = res.data.data.length === 16 ? 'more' : 'nomore';
          if (page === 1) {
            this.datalist = res.data.data
          } else {
            this.datalist = [...this.datalist, ...res.data.data];
          }


          this.otherInfo = (res.data.other instanceof Array) ? null : res.data.other;
          let that = this;
          // this.$nextTick(() => {
          //   let newList = JSON.parse(JSON.stringify(that.datalist))
          //   newList.forEach((item, index) => {
          //     if (!item.nameScale) {
          //       let domContainerWidth = that.$refs['entity-name-container' + index][0].clientWidth;
          //       let domTextWidth = that.$refs['entity-name' + index][0].clientWidth;
          //
          //       if (domTextWidth <= domContainerWidth) {
          //         item.nameScale = 'scale(1)';
          //       } else {
          //         let r = domContainerWidth / domTextWidth;
          //         item.nameScale = 'scale(' + r + ')'
          //       }
          //     }
          //
          //   })
          //   that.datalist = newList
          //
          // })
        }).catch(err => {
          console.log(err)
          this.loading = false;
          this.LOCK_STATUS = false;
        })
      },


      reloadAction() {
        window.location.reload()
      },
      handleUserSearch(e) {
        if (e === '') {
          this.userList = []
        } else {
          this.userLoading = true
          this.rq.get('/merapi/sorting/v2/client/all?name=' + e + '&page_size=100').then(res => {
            console.log('prod', res)
            this.userList = res.data.data;
            this.userLoading = false;
          }).catch(e => {
            this.userLoading = false;
          })
        }
      },
      sortingAction(item) {
        this.$router.push({
          name: 'customerSortingPro',
          query: {
            send_time: this.dataObj.send_time,
            sorting_client_id:  item.sorting_client_id,
            client: item.name
          }
        })
      },
      dateChange(type) {
        let date  =  (new Date()).getTime()
        if (type === '-1') {
          date = date - 24 * 60 * 60 * 1000;
        } else if (type === '+1') {
          date = date + 24 * 60 * 60 * 1000;
        }
        this.filterValues.send_time = this.moment(parseInt(date)).format('YYYY-MM-DD')
      }
    }
  }
</script>

<style scoped lang="less">
  .single-sorting-container {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #F1F2F2;
    overflow: hidden;
    position: relative;

    .header {
      display: flex;
    }
  }

  .sortingformbox {

    background: #fff;
    display: flex;

    align-items: center;
    flex: 1;
    margin-left: 0.7%;
    .box-left {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .bgBlue+.bgBlue {
        margin-top: 10px;
      }
      .bgBlue {
        background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        height: 50px;
        outline: none;
        margin: 0 25px;
      }
    }
    .bgBlue {
      background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
      border-radius: 5px;
      border: none;
      font-size: 20px;
      font-weight: 600;
      color: #FFFFFF;
      height: 80px;
      outline: none;
      margin: 0 25px;
    }
  }
  .sortingForm {
    display: flex;
    align-items: center;
  }
  .sortingformbox /deep/ .ant-input, /deep/ .ant-select-selection__rendered, /deep/ .ant-select-selection, /deep/ .ant-cascader-picker-label {
    height: 80px !important;
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    line-height: 50px;
    /*box-sizing: content-box !important;*/
  }
  .sortingformbox .filter-btn {
    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
    border-radius: 5px;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    height: 80px;
    outline: none;

  }

  .sortingformbox /deep/ .ant-form-item {
    margin-bottom: unset !important;
  }
  .sortingformbox /deep/ .ant-form-item-control-wrapper {
    width: 100%;
  }
  .sortingformbox /deep/ .ant-cascader-picker-label {
    line-height: unset;
  }
  .itemFormItem{
    width: 100%
  }
  .itemFormItem + .itemFormItem {
    margin-left: 20px;
  }

  .btn-form-line {
    height: 100%;
    width: 1px;
    background-color: #F1F2F2;
  }

  .form-container {
    padding-left: 25px;
    padding-right: 20px;
    width: 100%;
  }






  .sorting-detail-view {
    box-sizing: border-box;
    /*margin-top: 10px;*/
    height: 660px;
    /*padding-bottom: 20px;*/
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 10px;
    &::-webkit-scrollbar { // 直接复制黏贴到样式页.css，完美解决
      display: none;
      /* background-color:transparent; */
    }

    overflow-y: scroll;
    overflow-x: hidden;
    .sorting-detail-item {
      margin-top: 10px;
      overflow: hidden;
      /*height: 120px;*/
      background-color: #fff;
      /*box-shadow: 0px 5px 15px 0px rgba(56, 62, 68, 0.13);*/
      border-radius: 5px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: space-between;
      padding-bottom: 40px;
      padding-top: 40px;
      .item-status {
        position: absolute;
        left: 0;
        top: 0;
        padding: 5px;
        font-size: 16px;
        color: #fff;
      }
      .entity-name {
        /*overflow: hidden;*/

        /*text-overflow: ellipsis;*/

        white-space: pre-wrap;
        font-size: 23px;

        font-weight: 600;
        color: #0F0F0F;
        height: 80px;
        text-align: center;
        vertical-align: middle;

        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .num-info {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        white-space: nowrap;
        font-weight: 600;
        height: 40px;
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }



      .statusBg-1 {
        background: #BC1D00;
      }

      .statusBg-2 {
        background: #34BC00;
      }

      .statusBg-3 {
        background: #A6A6A6;
      }

      .statusBg-4 {
        background: #BC7B00;
      }

      .mark-view {
        display: flex;
        align-items: center;

        .mark-text {
          flex: 1;
          /*flex-wrap: nowrap;*/
          /*white-space:nowrap;*/
          /*overflow:hidden;*/
          /*text-overflow:ellipsis;*/
        }
      }
    }

  }






  .filter-input {
    width: 100%;
    .date-item {
      height: 50px;
      width: 60px;
      font-size: 16px;
      font-weight: 600;
      border: 1px solid #C6CBD4;
      border-radius: 2px;
      color: #000000;
      display: flex;align-items: center;justify-content: center;
      margin-right: 15px;
    }

    .input-label {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .radio-item, /deep/ .ant-input, /deep/ .ant-select-selection__rendered, /deep/ .ant-select-selection, /deep/ .ant-cascader-picker-label {
      height: 50px !important;
      font-size: 16px;
      font-weight: 600;
      color: #242424;
      line-height: 50px;
    }
    .radio-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .radio-item {
        margin-right: 15px;
        margin-top: 10px;
        border: 1px solid #C6CBD4;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0  15px;

        .radio-dot {
          width: 21px;
          height: 21px;
          border: 2px solid #B2B2B2;
          border-radius: 20px;

          margin-right: 10px;
          box-sizing: content-box;
          &>div {
            margin-top: 3px;
            margin-left: 3px;
            border-radius: 8px;
            width: 15px;
            height: 15px;
            background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
          }
        }
        .check-dot {
          border-radius: 2px;
          &>div {
            border-radius: 2px;
          }
        }
      }
    }
  }
  .filter-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    .filter-btn + .filter-btn {
      margin-left: 20px;
    }
    .filter-btn {
      height: 50px;
      width: 150px;
      font-size: 18px;

    }
    .cancel-btn {
      color: #23409F;
    }
    .confire-btn  {
      background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
      color: white;
    }
  }
  .content-view {
    width: 100%;
    height: 660px;
    position: relative;
  }
</style>
